import React, { useState } from "react"
import Layout from "../../chunks/layout"
import { Helmet } from "react-helmet"
import Main from "../../components/main"
import Pricing from "../../components/pricing"
import GuaranteeSection from "../../components/guaranteesection"
import Features from "../../components/features"
import Faqs from "../../components/faqs"
import Testimonials from "../../components/testimonials"
import HowItWorks from "../../components/howitworks"

const YouTube = props => {
  const [productName, setProductName] = useState(props.productName)
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout | Boostik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Main
        viewName="youtube"
        title="ACCELERATE YOUR GROWTH ON YOUTUBE."
        subTitle=""
        description="With the help of an initial boost it’s much easier to grow your channel if you are new to the platform. Going viral has never been easier!
        Fast service and an easy-to-use dashboard, here is all you need to kickstart your account.
        "
      />
      <div className="w-full bg-gray">
        <div className="background-gray-wave-separator-rotated"></div>
      </div>
      <div className="section-container bg-gray">
        <div className="section-content">
          <div className="flex justify-center items-center">
            <button
              className={`pricing-menu-button ${
                productName === "likes" &&
                `pricing-menu-button-selected color-youtube shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("likes")
              }}
              onKeyDown={() => {
                setProductName("likes")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-heart text-3xl"></i>
              </div>
              Likes
            </button>
            <button
              className={`pricing-menu-button ${
                productName === "views" &&
                `pricing-menu-button-selected color-youtube shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("views")
              }}
              onKeyDown={() => {
                setProductName("views")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-show text-3xl"></i>
              </div>
              Views
            </button>
          </div>
        </div>
        <Pricing
          viewName="youtube"
          categoryName="youtube"
          productName={productName}
        />
      </div>
      <GuaranteeSection viewName="youtube" />
      <div className="section-container background-section-gray">
        <Features viewName="youtube" categoryName="youtube" />
      </div>
      <HowItWorks viewName="youtube" categoryName="youtube" />
      {/* <div className="w-full flex flex-col justify-center background-gradient-youtube">
        <div
          className={`background-${
            props.viewName !== "home" ? "gray" : "white"
          }-wave-separator-rotated`}
        ></div>
        <div className="container mx-auto text-center p-10">
          <div className="section-title color-white">
            Why buy <b>Youtube Services</b>?
          </div>
        </div>
        <div className="container mx-auto p-10 text-white">
          YouTube is powerful, but let’s be honest, you need to go all out if
          you want to make the most of it. Getting more views on YouTube is a
          major plus to boost your channel at the beginning. Every YouTube
          content producer aims to reach millions of people. Several factors
          influence the positioning on YouTube, and one of them is the number of
          views. We offer the highest quality YouTube views on the market at a
          competitive price. In a highly competitive sector, it is necessary to
          obtain support from a professional platform in the initial stage in
          order to increase the number of views naturally.
          <p className="mt-10">
            If you have some kind of video that doesn't reach your desired
            popularity, buy YouTube views. If you're producing content and you
            want it to start up quickly, buy fast YouTube views and see the
            results.
          </p>
        </div>
        <div className="background-gray-wave-separator"></div>
      </div> */}
      <div className="background-section-gray">
        <div className="container mx-auto text-center">
          <div className={`section-title color-youtube mt-10`}>
            Some Reviews and Customer Feedback
          </div>
          <div>
            <Testimonials
              categoryName="youtube"
              testimonialsList={[
                {
                  name: "Matthew Taylor",
                  review: 4,
                  comment:
                    "“It is the first time I buy and so far I'm content, they deliver what they promise, and prices are good”.",
                  userImage: "user3",
                },
                {
                  name: "Gabriel Perez",
                  review: 4,
                  userImage: "user1",
                  comment:
                    "“It’s the second time I buy promotion services from these guys and so far I’m very satisfied”",
                },
                {
                  name: "Sarah Lewis",
                  review: 5,
                  userImage: "user7",
                  comment:
                    "“I wanted to increase the number of views on my channel a bit and give it a push, it's reliable!”",
                },
                {
                  name: "Ben Johnson",
                  review: 5,
                  userImage: "user12",
                  comment: "“It actually works and all of them were legit”",
                },
                {
                  name: "Marcus T.J",
                  review: 4.5,
                  comment: "“It really works and it’s worth the price”",
                  userImage: "user11",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Faqs
        listOfFaqs={[
          {
            title: "WHEN WILL MY ORDER START?",
            description:
              "The start time for all services can vary and is listed on the Service Description. Some services have an instant start time and others may have a start time that is delayed up to 24 hours. Please be sure you review the Service Description for the service you are interested in before placing the order. Please note that for YouTube services, YouTube doesn’t update all stats in real-time so it can take up to 6 hours for you to see the effects from your order.",
          },
          {
            title: "IS THIS SAFE FOR MY ACCOUNT?",
            description:
              "Yes, it’s 100% safe to use Boostik. All of our social media marketing services comply fully with the terms, conditions and guidelines of whichever platform we’re dealing with. There is absolutely no chance whatsoever that your account will be penalized in any way. We constantly monitor all of the major social media players for updates and adapt our services accordingly. This means that you will always be one step ahead and your account will never be in jeopardy.",
          },
          {
            title: "HOW FAST WILL I RECEIVE THE SERVICES?",
            description:
              "That depends on which service you are ordering. For YouTube services, YouTube doesn’t update all stats in real-time so it can take up to 6 hours for you to see the effects from your order. The longest you will ever have to wait for any service will be 24 hours. Please note that delivery times are estimated and may vary during busier periods. In case you need more information about our delivery times, just send us an email. We’ll be glad to resolve your doubts. Whichever package you choose to order, you can rest assured that you will get it in the fastest possible time.",
          },
          {
            title: "DO YOU NEED MY PASSWORD?",
            description:
              "It is not necessary to have your password in order to deliver a high-quality service. All we need is a link to the content you want to promote to get started. We do NOT require and will never ask for your password or account access. All we need is your Social Media URL/username and your email so that we can contact you regarding your order process. By doing so, we eliminate the possibility of your Social Media profile being breached by any third parties.",
          },
          {
            title: "WHICH PACKAGE IS RIGHT FOR ME?",
            description:
              "We have assigned a dedicated team of experts to constantly analyze Youtube. This makes it possible for us to always provide the 'Best Value' option for the most unbeatable price on the Internet. As for the right package for you, this will depend on your requirements. Remember that we are at your disposal in case you need further help.",
          },
          {
            title: "WHAT PAYMENT METHODS DO YOU ACCEPT?",
            description:
              "At the moment we accept payments through credit and debit cards.",
          },
          {
            title: "CAN I GET A REFUND?",
            description:
              "Your order is eligible for refund if it has not been completed yet. If we fail to deliver your order, you can request for a full refund. Please note different packages have different delivery periods. ",
          },
        ]}
        viewName="youtube"
      />
    </Layout>
  )
}

export default YouTube
